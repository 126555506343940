import React from 'react';

const Success = props => {

  return (

    <section className="sign-up-thanks">
      <div className="sign-up-thanks-content">
        <div className="sign-up-thanks-message">
          <h1>Thanks for being as excited as we are!</h1>
          <p className="sign-up-thanks-subtitle"> 
            We'll send you an email soon and give you a heads up when we're live!
          </p> 
        </div>
        <div className="sign-up-thanks-image"></div>
      </div>
    </section>
  )
};

export default Success;
