
import React from "react";

import Success from "../components/Success.js";
import Layout from "../components/Layout";


const EmailSuccess = () => (
    <Layout>
        <Success/>
    </Layout>
  );
  
  export default EmailSuccess;
  